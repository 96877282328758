import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

function ProjectNav({ prev, next }) {
  return (
    <div className="flex flex-col justify-start text-blue-500 sm:flex-row sm:space-x-5">
      <Link to={`/showcase`}>
        <div className="flex items-center">
          <StaticImage src="../../images/nav-projects-all.png" />
          <span className="ml-2">All Projects</span>
        </div>
      </Link>
      <div className="flex space-x-3 mt-2 sm:mt-0">
        {prev && (
          <Link to={`/showcase/${prev.id}`}>
            <div className="flex items-center">
              <StaticImage src="../../images/nav-projects-prev.png" />
              <span className="ml-2">Previous</span>
            </div>
          </Link>
        )}
        {next && (
          <Link to={`/showcase/${next.id}`}>
            <div className="flex items-center">
              <StaticImage src="../../images/nav-projects-next.png" />
              <span className="ml-2">Next</span>
            </div>
          </Link>
        )}
      </div>
    </div>
    // <div className="flex items-center gap-x-4">
    //   <Link to={`/showcase`}>
    //     <div className="flex items-center">
    //       <img src={allProjects} />
    //       <span className="ml-2  text-blue-500">All Projects</span>
    //     </div>
    //   </Link>
    //   {prev && (
    //     <Link to={`/showcase/${prev.id}`}>
    //       <div className="flex items-center">
    //         <img src={prevProject} />
    //         <span className="ml-2 text-blue-500">Previous</span>
    //       </div>
    //     </Link>
    //   )}
    //   {next && (
    //     <Link to={`/showcase/${next.id}`}>
    //       <div className="flex items-center">
    //         <img src={nextProject} />
    //         <span className="ml-2 text-blue-500">Next</span>
    //       </div>
    //     </Link>
    //   )}
    // </div>
  );
}

export default ProjectNav;
