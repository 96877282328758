import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

function ProjectImages({ images }) {
  const [imageIndex, setImageIndex] = useState(0);
  const [image, setImage] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [caption, setCaption] = useState(null);

  useEffect(() => {
    if (images[imageIndex]?.video) {
      setIsVideo(true);
      setCaption(images[imageIndex].caption);
      setImage(images[imageIndex]?.video);
      return;
    } else {
      setIsVideo(false);
    }
    setImage(getImage(images[imageIndex].img));
    setCaption(images[imageIndex].caption);
  }, [images, imageIndex]);

  const imageChangeHandler = (direction) => {
    let imageIndexToSet = imageIndex;

    if (direction === 1) {
      imageIndexToSet = imageIndex + 1;
      if (imageIndexToSet >= images.length) {
        imageIndexToSet = 0;
      }
    } else {
      imageIndexToSet = imageIndex - 1;
      if (imageIndexToSet < 0) {
        imageIndexToSet = images.length - 1;
      }
    }

    setImageIndex(imageIndexToSet);
  };

  if (!image) {
    return null;
  }

  return (
    <div>
      <div className="grid grid-cols-12 items-center justify-items-center gap-3">
        <div className="col-start-1 col-span-1">
          <StaticImage
            src="../../images/nav-project-image-prev.png"
            className="hover:opacity-50 cursor-pointer w-3 sm:w-4"
            alt="Previous"
            onClick={() => imageChangeHandler(-1)}
          />
        </div>
        <div className="col-start-2 col-span-10">
          {isVideo ? (
            <div style={{ width: '900px', height: '500px', position: "relative" }} className="video-player">
              <iframe
                src={image} 
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                title="Showcase: XNAT"
              ></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          ) : (
            <GatsbyImage className="object-cover" image={image} />
          )}
        </div>
        <div className="col-start-12 col-span-1">
          <StaticImage
            className="hover:opacity-50 cursor-pointer w-3 sm:w-4"
            src="../../images/nav-project-image-next.png"
            alt="Next"
            onClick={() => imageChangeHandler(1)}
          />
        </div>
      </div>
      <div className="hidden sm:grid grid-cols-12">
        <div className="col-start-2 col-span-10">
          <p className="mt-5 pl-2 border-l-2 border-blue-500 text-blue-50 text-md">
            {caption}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectImages;
