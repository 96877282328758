import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Page from "../../components/Page";
import SEO from "../../components/SEO";
import ProjectNav from "../../components/showcase/ProjectNav";
import ProjectImages from "../../components/showcase/ProjectImages";
import Section from "../../components/Section";

function ShowcaseTemplate({ data, pageContext }) {
  const showcaseData = data.showcasesJson;
  const { next, prev } = pageContext;
  return (
    <Page>
      <Section>
        <SEO title={showcaseData.title} />
        <main className="lg:grid lg:grid-cols-12 gap-x-3">
          <div className="col-start-2 col-span-9">
            <ProjectNav prev={prev} next={next} />
          </div>
          <h1 className="col-start-2 col-span-9 lg:text-4xl md:text-3xl text-2xl  text-blue-100 mt-6">
            {showcaseData.title}
          </h1>
          <div className="col-start-1 col-span-12 my-5">
            <ProjectImages  
              images={showcaseData.images}
              thumbnail={showcaseData.thumbnail}
            />
          </div>
          <div className="col-start-2 col-span-7 text-white pr-2">
            <div className="flex items-center gap-x-3">
              <StaticImage src="../../images/projects-about.png" />
              <span className="text-md text-blue-100">OVERVIEW</span>
            </div>
            <p className="mt-2 text-md">
              {showcaseData.overview.split("\n").map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </p>{" "}
          </div>
          {showcaseData.quote ? (
            <div className="col-span-3 text-white">
              <div className="flex items-center gap-x-3">
                <StaticImage src="../../images/projects-quote.png" />
                <span className="text-md text-blue-100">QUOTE</span>
              </div>
              <p className="mt-2 text-md text-blue-100">{`\"${showcaseData.quote.text}\"`}</p>
              <div className="text-md mt-3 ml-2">
                - {showcaseData.quote.author}
              </div>
              <div className="ml-5 mt-1  text-md">
                {showcaseData.quote.position}
              </div>
            </div>
          ) : null}
        </main>
      </Section>
    </Page>
  );
}
export const query = graphql`
  query GetShowCaseById($id: String) {
    showcasesJson(id: { eq: $id }) {
      id
      images {
        caption 
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
        video
      }
      overview
      quote {
        author
        text
        position
      }
      title
    }
  }
`;

export default ShowcaseTemplate;
